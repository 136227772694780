<script>
  import Base from '@backend/Base.vue';
  import BOGen from '@helper/BOGen';
  import draggable from 'vuedraggable'

  export default {
    name: "BoRecipe",
    extends: Base,
    components: {
      draggable
    },
    data() {
      return {
        Name: "BoRecipe",
        mrLevel: {},
        category: {},
        statusRecipe: {},
        mpTitleLength:0,
      }
    },
    mounted() {
      this.$set(this.$root, 'page', this)
      this.refreshData()
    },
    methods: {
      endDrag() {
        BOGen.apirest('/' + this.Name, {
          data: this.data.data,
          type: 'sort'
        }, (err, resp) => {
        }, "POST");
      },
      sort(){
        this.filter.sort = this.filter.sort == 'all' ? '' : 'all'
        if(this.filter.sort == 'all'){
          this.filter.category=''
          this.filter.statusProduct=''
        }
        var query = this.filter
        delete query.page
        this.$router.push({name:this.Name, query: query})
      }
    },
    watch: {
      '$route.query'() {
        if(this.$route.params.id){
          this.$set(this.row, 'category', this)
          this.$set(this.row, 'statusRecipe', this)
          this.$set(this.row, 'search', this)
          this.$set(this.row, 'page', this)
          setTimeout(()=>{
            this.row.category = this.$route.query.category
            this.row.statusRecipe = this.$route.query.statusRecipe
            this.row.search = this.$route.query.search
            this.row.page = this.$route.query.page
          },500)
        }
        this.refreshData()
      },
      'filter.category'(v) {
        if (!v) return
        this.search()
      },
      'filter.statusRecipe'(v) {
        if (!v) return
        this.search()
      },
      'row.mr_title'(v) {
        if (!v) return
        this.mpTitleLength=v.length
      },
    }
  };
</script>

<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header" v-if="!$route.params.id">
            <VForm @resp="search">
              <div class="row">
                <div class="col-sm-2">
                  <h5 class="card-title">Recipe List</h5>
                </div>
                <div class="col-sm-2">
                    <button class="btn btn-success" type="button" @click="sort()">
                      <i class="fas fa-sort"></i> Sorting All
                    </button>
                </div>
                <div class="col-sm-2">
                  <select2 :options="mrCat" :object="['mrc_id','mrc_name']" v-model="filter.category">
                    <option value="">-- Select Category --</option>
                  </select2>
                </div>
                <div class="col-sm-2">
                  <select2 :options="statusRecipe" v-model="filter.statusRecipe">
                    <option value="">-- Select Status --</option>
                  </select2>
                </div>
                <div class="col-sm-3">
                  <div class="form-group mb-0">
                    <div class="input-group">
                      <input type="text" v-model="filter.search" v-on:keyup.enter="search" class="form-control"
                        placeholder="Search...">
                      <div class="input-group-append">
                        <button class="btn btn-info" type="button" @click="search()">
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-1">
                  <router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
                </div>
              </div>
            </VForm>
          </div>
          <div class="card-body">

            <div class="row" v-if="!$route.params.id">
              <div class="col-12">
                <div class="alert alert-info">
                  Image Requirements for Recipe Cover :
                  <ul class="mb-0 mt-2">
                    <li><strong>Dimension: </strong><span>850x500px</span></li>
                    <li><strong>Format: </strong><span>jpeg,jpg,png</span></li>
                    <li><strong>Max. Size: </strong><span>2 MB</span></li>
                  </ul>
                </div>
              </div>
              <div class="col-12">
                <draggable v-model="data.data" tag="div" class="row" @end="endDrag">
                  <div class="col-md-3 mb-3" v-for="(v,k) in data.data" :key="k">
                    <div class="item-list collection">
                      <div class="row text-center">
                        <div class="col-md-12">
                          <div class="product-img recipe-cat mb-2">
                            <img :src="uploader(v.mr_image,'250')" alt="prod">
                            <div class="pro-img-overlay">
                              <router-link class="bg-info" :to="{name:Name,params:{id:v.id},query:$route.query}" v-tooltip="'Edit'"><i
                                  class="ti-marker-alt"></i></router-link>
                              <a href="javascript:;" class="bg-danger" @click="deleteItem($event,k)"
                                v-tooltip="'Remove'"><i class="ti-trash"></i></a>
                            </div>
                            <span class="label label-success" v-if="v.mr_is_active=='Y'">Active</span>
                            <span class="label label-danger" v-else>Inactive</span>
                          </div>
                          <small class="text-danger mt-2">{{v.category}}</small>
                          <h5 class="card-title mt-1">{{v.mr_title}}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </draggable>
                <div v-if="NotFound" class="text-center col-md-12">
                  <h5 class="tc">{{NotFound}}</h5>
                </div>
                <div v-if="data.data===false" class="text-center col-md-12">
                  <LoadingSpinner light></LoadingSpinner>
                </div>
                <div class="panel-body">
                  <div class="pull-right">
                    <Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" v-if="$route.params.id">
              <div class="col-12">
                <VForm @resp="submitForm" method="post">
                  <div class="card-body">
                    <div class="row mb-3">
                      <div class="col-md-8">
                        <h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
                      </div>
                    </div>
                    <div class="info"></div>
                    <div class="row">
                      <div class="col-sm-7">
                        <div class="row">
                          <div class="col-7">
                            <BoField name="mr_type">
                              <div class="row">
                                <radio name="mr_type" v-model="row.mr_type" option="G"
                                  :attr="validation('mr_type')">Guide</radio>
                                <radio name="mr_type" v-model="row.mr_type" option="R">Recipe</radio>
                              </div>
                            </BoField>
                          </div>
                        </div>
                        <BoField name="mr_title" v-model="row.mr_title"></BoField>
                        <small class="mb-2 d-inline-block text-info">{{validation('mr_title').maxlength-mpTitleLength}} character remaining </small>
                        <BoField name="mr_category">
                          <select2 name="mr_category" v-bind="validation('mr_category')" :options="MrCategory"
                            :object="['mrc_id','mrc_name']" v-model="row.mr_category" multiple>
                          </select2>
                        </BoField>
                        <BoField name="mr_ingredients" mandatory v-if="row.mr_type=='R'">
                          <CKEditor name="mr_ingredients" v-model="row.mr_ingredients"
                            v-bind="validation('mr_ingredients')"></CKEditor>
                        </BoField>
                        <BoField name="mr_instruction" mandatory :label="row.mr_type=='R' ? 'How to Make' : 'Description'">
                          <CKEditor name="mr_instruction" v-model="row.mr_instruction"
                            v-bind="validation('mr_instruction')"></CKEditor>
                        </BoField>

                        <div class="row mb-3">
                          <div class="col-md-8">
                            <h5 class="card-title">Meta Content</h5>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12">
                            <BoField name="mr_seo_title" :attr="{minlength:'3'}" v-model="row.mr_seo_title" mandatory>
                            </BoField>
                            <BoField name="mr_seo_keyword" mandatory>
                              <TagsInput name="mr_seo_keyword" v-model="row.mr_seo_keyword"
                                :attr="validation('mr_seo_keyword')"></TagsInput>
                            </BoField>
                          </div>
                          <div class="col-sm-12">
                            <BoField name="mr_seo_desc" mandatory>
                              <textarea name="mr_seo_desc" rows="5" :attr="{minlength:'10'}" v-model="row.mr_seo_desc"
                                v-bind="validation('mr_seo_desc')" class="form-control"></textarea>
                            </BoField>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-7">
                            <BoField name="mr_is_active">
                              <div class="row">
                                <radio name="mr_is_active" v-model="row.mr_is_active" option="Y"
                                  :attr="validation('mr_is_active')">Active</radio>
                                <radio name="mr_is_active" v-model="row.mr_is_active" option="N">Inactive</radio>
                              </div>
                            </BoField>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-5">
                        <BoField name="mr_image">
                          <Uploader name="mr_image" type="recipe" uploadType="cropping" :param="{thumbnail:true}"
                            v-model="row.mr_image"></Uploader>
                        </BoField>
                        <BoField name="mr_author" v-model="row.mr_author"></BoField>

                        <div class="row mb-3 mt-4">
                          <div class="col-md-8">
                            <h5 class="card-title">Nutrient Content</h5>
                          </div>
                        </div>
                        <div class="row" v-for="(v,k) in row.mr_nutrient" :key="k">
                          <div class="col-md-5">
                            <BoField :label="'Title'" :attr="{placeholder:'Ex: Kalori'}" v-model="v.name"></BoField>
                          </div>
                          <div class="col-md-4">
                            <BoField :label="'Nutrient Value'" :attr="{placeholder:'Ex: 60 kkal'}" v-model="v.val">
                            </BoField>
                          </div>
                          <div class="col-md-3" style="margin-top: 31px;">
                            <a @click="row.mr_nutrient.push({name:'',val:''})" href="javascript:;"
                              class="btn btn-info mr-1" v-tooltip="'Add Nutrient'"><i class="ti-plus"></i></a>
                            <a v-if="row.mr_nutrient.length>1" @click="row.mr_nutrient.splice(k,1)" href="javascript:;"
                              class="btn btn-danger" v-tooltip="'Delete Nutrient'"><i class="ti-trash"></i></a>
                          </div>
                        </div>

                        <BoField name="mr_product">
                          <select2 name="mr_product" v-bind="validation('mr_product')" :options="MrProduct"
                            :object="['mp_id','mp_title']" v-model="row.mr_product" multiple>
                          </select2>
                        </BoField>

                      </div>
                      <div class="col-sm-12">
                        <div class="text-right">
                          <button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit form
                            <i class="icon-arrow-right14 position-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </VForm>
              </div>
            </div>


          </div>
        </div>

      </div>
    </div>

  </div>
</template>